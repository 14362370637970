var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('mdb-card',{staticClass:"mb-2"},[_c('mdb-card-header',{attrs:{"color":"default"}},[_vm._v("查詢")]),_c('mdb-card-body',{staticClass:"pt-0"},[_c('mdb-row',[_c('mdb-col',{attrs:{"md":"3"}},[_c('mdb-select',{attrs:{"label":"分類","selectAllPlaceholder":"選取全部","search":"","searchPlaceholder":"搜尋分類","placeholder":"請選取分類","options":_vm.product_classList},on:{"getValue":function (val) {
                                _vm.search.class = val;
                            }}})],1),_c('mdb-col',{attrs:{"md":"3"}},[_c('mdb-input',{attrs:{"label":"商品編號"},model:{value:(_vm.search.code),callback:function ($$v) {_vm.$set(_vm.search, "code", $$v)},expression:"search.code"}})],1),_c('mdb-col',{attrs:{"md":"3"}},[_c('mdb-input',{attrs:{"label":"商品名稱"},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", $$v)},expression:"search.name"}})],1),_c('mdb-col',{staticClass:"pt-3 text-sm-right text-md-left",attrs:{"md":"3"}},[_c('mdb-btn',{staticClass:"mt-3",attrs:{"color":"default","size":"sm"},on:{"click":function($event){_vm.search.page = 1;
                            _vm.searchdata();}}},[_c('i',{staticClass:"fa fa-search"}),_vm._v(" 搜  尋 ")])],1)],1)],1)],1),_c('mdb-card',[_c('mdb-card-body',[_c('table',{staticClass:"table table-striped rwd-table-dispersion"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"4em"}},[_vm._v("#")]),_c('th',{staticClass:"text-nowrap",staticStyle:{"cursor":"pointer","width":"9.5em"},on:{"click":function($event){return _vm.sort_data('code')}}},[_vm._v(" 商品編號 "),_c('i',{staticClass:"ml-2 fa",class:{
                                    'fa-sort': _vm.search.sortcolumn != 'code',
                                    'fa-sort-up': _vm.search.sortcolumn == 'code' && _vm.search.sort == 'asc',
                                    'fa-sort-down': _vm.search.sortcolumn == 'code' && _vm.search.sort == 'desc',
                                }})]),_c('th',{staticClass:"text-nowrap",staticStyle:{"cursor":"pointer","width":"10em"},on:{"click":function($event){return _vm.sort_data('name')}}},[_vm._v(" 商品名稱 "),_c('i',{staticClass:"ml-2 fa",class:{
                                    'fa-sort': _vm.search.sortcolumn != 'name',
                                    'fa-sort-up': _vm.search.sortcolumn == 'name' && _vm.search.sort == 'asc',
                                    'fa-sort-down': _vm.search.sortcolumn == 'name' && _vm.search.sort == 'desc',
                                }})]),_c('th',{staticClass:"text-nowrap"},[_vm._v(" 規格 ")]),_c('th',{staticClass:"text-nowrap text-center",staticStyle:{"cursor":"pointer","width":"7.5em"},on:{"click":function($event){return _vm.sort_data('cost')}}},[_vm._v(" 成本 "),_c('i',{staticClass:"ml-2 fa",class:{
                                    'fa-sort': _vm.search.sortcolumn != 'cost',
                                    'fa-sort-up': _vm.search.sortcolumn == 'cost' && _vm.search.sort == 'asc',
                                    'fa-sort-down': _vm.search.sortcolumn == 'cost' && _vm.search.sort == 'desc',
                                }})]),_c('th',{staticClass:"text-nowrap",staticStyle:{"cursor":"pointer","width":"7.5em"},on:{"click":function($event){return _vm.sort_data('price')}}},[_vm._v(" 售價 "),_c('i',{staticClass:"ml-2 fa",class:{
                                    'fa-sort': _vm.search.sortcolumn != 'price',
                                    'fa-sort-up': _vm.search.sortcolumn == 'price' && _vm.search.sort == 'asc',
                                    'fa-sort-down': _vm.search.sortcolumn == 'price' && _vm.search.sort == 'desc',
                                }})]),_c('th',{staticStyle:{"width":"8.5em"}},[_vm._v("庫存")]),_c('th',{staticClass:"px-0",staticStyle:{"width":"9em"}})])]),_c('tbody',[_vm._l((_vm.productList),function(p,pk){return _c('tr',{key:("p_" + pk)},[_c('th',{attrs:{"data-title":"#"}},[_vm._v(" "+_vm._s((_vm.data.nowPage - 1) * 10 + pk + 1)+" ")]),_c('td',{attrs:{"data-title":"商品編號"}},[_vm._v(_vm._s(p.code)+" ")]),_c('td',{attrs:{"data-title":"商品名稱"}},[_vm._v(_vm._s(p.name))]),_c('td',{attrs:{"data-title":"商品規格"}},[_vm._v(_vm._s(p.info))]),_c('td',{staticClass:"text-right",attrs:{"data-title":"成本"}},[_vm._v(_vm._s(_vm.$numeral(p.cost).format('0,0'))+" ")]),_c('td',{staticClass:"text-right",attrs:{"data-title":"售價"}},[_vm._v(" "+_vm._s(_vm.$numeral(p.price).format('0,0'))+" ")]),_c('td',{attrs:{"data-title":"庫存"}}),_c('td',{staticClass:"text-right text-md-left"},[_c('mdb-btn',{attrs:{"color":"primary","size":"sm"},on:{"click":function($event){return _vm.$router.push(("/product/edit/?id_product=" + (p.id)))}}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v("編輯 ")])],1)])}),_c('tr',[_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.productList.length == 0),expression:"productList.length == 0"}],attrs:{"colspan":"7","scope":"row"}},[_vm._v("無資料")])])],2)]),_c('page',{attrs:{"nowPage":_vm.data.nowPage,"totalPage":_vm.data.totalPage},on:{"switchpage":function (pg) {
                        _vm.search.page = pg;
                        _vm.searchdata();
                    }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }